<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <div class="flex flex-col mt-2 sm:flex-row justify-between">
          <div role="tablist" class="nav nav-tabs">
            <div class="flex">
              <!-- <LWDropdownMultiSelect class="mr-2" label="Bank" :options="bankOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onBankSelected($event)" />
              <LWDropdownMultiSelect class="mr-2" label="Working Date" :options="workingDateOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onWorkingDateSelected($event)" />
              <LWDropdownMultiSelect class="mr-2" label="Status" :options="statusOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onStatusSelected($event)" /> -->
            </div>
          </div>
          <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-gray-700 dark:text-gray-300">
              <input type="text" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search"
              :disabled="isLoading || !isContentLoaded" v-model="searchQuery" />
              <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mt-3 mr-3 right-1" />
            </div>
          </div>
        </div>
        <table v-if="isLoading || !isContentLoaded" class="table table-report mt-2">
          <tbody>
            <tr v-for="item in 3" :key="item" class="intro-x">
              <td>
                <a href="" class="font-medium whitespace-nowrap">
                  <Skeletor />
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
                </div>
              </td>
              <td class="">
                <Skeletor />
              </td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <Skeletor />
                </div>
              </td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <Skeletor />
                </div>
              </td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <Skeletor />
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">
                  <Skeletor />
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">
                  <Skeletor />
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else-if="isLoading === false && isContentLoaded ? listAccounts.length > 0 : false"
          class="table table-report mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Job Name</th>
              <th class="whitespace-nowrap">Credential Name</th>
              <th class="whitespace-nowrap">Working Date</th>
              <th class="text-center whitespace-nowrap">Bank Account Active</th>
              <th class="whitespace-nowrap">Schedule Date</th>
              <th class="text-center whitespace-nowrap">Schedule Time</th>
              <th class="text-center whitespace-nowrap">Status</th>
              <th class="text-center whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredAccounts" :key="item.id" class="intro-x">
              <td>
                <div class="flex justify-between items-center">
                  <div class="">
                    <!-- {{ item?.JobName ? shortJobName(item.JobName, 20) : '' }} -->
                    {{ item?.JobName }}
                  </div>
                </div>
              </td>
              <td class="w-80 ">
                {{ item?.CredentialName }}
              </td>
              <td>
                {{ item?.workingDateDay === '0' ? 'Intraday' : 'Backdate' }}
              </td>
              <td>
                <div class="text-center">
                  {{ item?.BankAccountActive }}
                </div>
              </td>
              <td v-if="item?.ScheduleDate !== null" class="text-center">
                {{ item?.ScheduleDate !== null ? item?.ScheduleDate : '-' }}
              </td>
              <td v-if="item?.ScheduleDate !== null" class="flex items-center justify-center">
                {{ item?.checkedScheduleTime.times.join(", ") ?? '' }}
                <!-- <LoadingIcon v-if="isLoading" icon="oval" class="text-center ml-3 w-6 h-6" /> -->
              </td>
              <td v-else colspan="2" class="text-yellow-500">
                <span> ** Please Update Job Schedule Date Schedule Time</span>
              </td>
              <td class="w-40">
                <div v-if="item.BankAccountActive > 0 && Boolean(item.isSystemEnable) === true && Number(item?.IsActive) !== 0
                  " class="flex items-center justify-center text-color-highlight">
                  Active
                </div>
                <div v-else-if="item.isSystemEnable === 0" class="flex items-center justify-center text-color-error">
                  Error
                </div>
                <div v-else class="flex items-center justify-center">
                  Inactive
                </div>
              </td>
              <td class="w-20">
                <div class="dropdown ml-auto">
                  <a class="dropdown-toggle w-5 h-5 block -mr-2" href="javascript:;" aria-expanded="false">
                    <MoreVerticalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                  </a>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a href="#" @click="onRowActiveBankClicked(item)"
                        class="items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                        <div v-if="Number(item?.IsActive) !== 0">
                          <XIcon class="w-4 h-4 mr-2" />
                          Inactive
                        </div>
                        <div v-else>
                          <CheckIcon class="w-4 h-4 mr-2" />
                          Active
                        </div>
                      </a>
                    </div>
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2" v-if="this.provider?.isConfig === true">
                      <a href="#" @click="onOpenEditAccountClicked(item)"
                        class="items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                        <div>
                          <EditIcon class="w-4 h-4 mr-2" />
                          Edit
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else-if="isLoading === false && isContentLoaded ? listAccounts.length < 1 : false"
          class="px-5 pt-5 mt-5 intro-y box">
          <div class="pb-5 -mx-5 border-b border-gray-200 dark:border-dark-5">
            <div style="justify-content: center; display: flex"
              class="flex-1 px-5 pt-5 mt-6 border-t border-l border-r border-gray-200 lg:mt-0 dark:text-gray-300 dark:border-dark-5 lg:border-t-0 lg:pt-0">
              <img style="box-shadow: none" class="w-12 mr-2" alt="centered image" :src="imgEmptyFolder" />
            </div>
            <div style="justify-content: center; display: flex"
              class="flex-1 px-5 pt-5 mt-6 border-t border-l border-r border-gray-200 lg:mt-0 dark:text-gray-300 dark:border-dark-5 lg:border-t-0 lg:pt-0">
              <div class="mt-5 font-medium text-center lg:text-left"> Job Schedule is not available.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <div id="delete-modal-preview" class="modal" tabindex="-1" data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <div class="text-white mt-2">
                Please Selected some accounts first.<br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-dismiss="modal" class="btn btn-primary w-24">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <div v-if="isLoading" class="text-center">
      <img class="h-14 mr-auto ml-auto mb-4" src="@/assets/images/Loading.gif" />
    </div>
  </div>
  <!-- BEGIN: Super Large Slide Over Content -->
  <div id="edit-modal" class="modal modal-slide-over" tabindex="-1" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a data-dismiss="modal" ref="elClose" href="javascript:;" :disabled="isUpdating" @click="onEditAccountClosed">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Edit Job Schedule
          </h2>
        </div>
        <!-- BEGIN: Modal Body -->
        <div class="modal-body modal-lg">
          <div class="mt-2">
            <label class="form-label">Job Name</label>
            <label class="text-theme-6 ml-2">*</label>
            <input :disabled="isUpdating" v-model="editAcc.JobName" type="text" class="form-control"
              placeholder="Job Name" />
            <div v-if="editAcc.JobName === '' ||
              editAcc.JobName === null
              " class="text-theme-6 mt-2">
              This field is required
            </div>
            <div v-if="duplicateMessage" class="text-theme-6 mt-2">
              {{ duplicateMessage }}
            </div>
          </div>
          <div class="mt-6">
            <div class="grid grid-flow-col auto-cols-max items-center">
              <h2 class="font-medium text-base mr-auto" :disabled="isUpdating">
                Credential Name :
                {{ editAcc?.CredentialName }}
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <div class="grid grid-flow-col auto-cols-max items-center">
              <h2 class="font-medium text-base mr-auto" :disabled="isUpdating">
                Bank Site :
                {{ editAcc.releateBankSite }}
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <div class="grid grid-flow-col auto-cols-max items-center">
              <h2 class="font-medium text-base mr-auto" :disabled="isUpdating">
                Working Date :
                {{ editAcc.workingDateDay === '0' ? 'Intraday' : 'Backdate' }}
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <div class="flex flex-col sm:flex-row mt-2">
              <label class="form-label">Bank Account</label>
            </div>
            <input v-for="bankAccount in listBankAccountNumbers" :key="bankAccount.id" class="form-control"
              :id="'validation-form-6-' + bankAccount.id" v-model="bankAccount.bankAccountNo" :type="inputType"
              :placeholder="inputPlaceholder" :disabled="isInputDisabled(bankAccount)" readonly />
            <div v-if="editAcc.bankNo?.replace(/-/g, '').length === 0" class="text-theme-6 mt-2">
              This field is required
            </div>
          </div>
          <div class="mt-6">
            <div class="flex flex-col sm:flex-row mt-2">
              <label class="form-label">Schedule Date</label>
              <label class="text-theme-6 ml-2">*</label>
            </div>
            <div class="flex flex-col sm:flex-row mt-2">
              <div class="form-check mr-2">
                <input id="radio-switch-4" class="form-check-input" type="radio" name="schedule-date" value="Everyday"
                  v-model="editAcc.ScheduleDate" :checked="editAcc.ScheduleDate === 'Everyday'" />
                <label class="form-check-label" for="radio-switch-4">Everyday</label>
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input id="radio-switch-5" class="form-check-input" type="radio" name="schedule-date" value="Weekday"
                  v-model="editAcc.ScheduleDate" :checked="editAcc.ScheduleDate === 'Weekday'" />
                <label class="form-check-label" for="radio-switch-5">Weekday</label>
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <div style="display:none">
                  <input id="radio-switch-6" class="form-check-input" type="radio" name="schedule-date" value=""
                    v-model="editAcc.ScheduleDate" :checked="editAcc.ScheduleDate === null" />
                </div>
                <label class="form-check-label" for="radio-switch-6"></label>
              </div>
            </div>
            <div v-if="editAcc.ScheduleDate === '' ||
              editAcc.ScheduleDate === null
              " class="text-theme-6 mt-2">
              This field is required
            </div>
          </div>
          <div class="mt-6">
            <div v-if="editAcc.workingDateDay === '0'">
              <div class="grid grid-cols-12 sm:grid-cols-24 gap-2 mt-2">
                <div v-for="(hour, index) in defaultHourIntraday" :key="index" class="form-check">
                  <input class="form-check-input" type="checkbox" :id="'checkbox-switch-4-' + index"
                    :disabled="editAcc.ScheduleDate === null" v-model="checkedHours.times" :value="hour" />
                  <label class="form-check-label" for="checkbox-switch-4">{{ hour }}</label>
                  <br v-if="(index + 1) % 3 === 0" />
                </div>
              </div>
            </div>
            <div class="text-theme-6 mt-2"
              v-if="editAcc.ScheduleDate !== null && noCheckedHours && editAcc.workingDateDay === '0'">Please check at
              least hour checkbox
            </div>
            <div v-else-if="editAcc.workingDateDay === '-1'">
              <div class="grid grid-cols-12 sm:grid-cols-24 gap-2 mt-2">
                <div v-for="(hour, index) in defaultHourBackdate" :key="index" class="form-check mr-2">
                  <input class="form-check-input" type="checkbox" :id="'checkbox-switch-5-' + index"
                    :disabled="editAcc.ScheduleDate === null" v-model="checkedHours.times" :checked="isBackdateChecked"
                    :value="hour" hidden />
                  <!-- <label class="form-check-label" for="checkbox-switch-4">{{ hour }}</label> -->
                  <br v-if="(index + 1) % 3 === 0" />
                </div>
              </div>
              <div v-if="isBackdateChecked"> - จะได้รับไฟล์ประมาณเวลา 08.00 น.</div>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer" style="margin-top: 25px; padding-right: 10px">
          <button :disabled="isUpdating" @click="onEditAccountClosed" type="button" data-dismiss="modal"
            class="btn btn-primary w-20 mr-6 text-left">
            <div class="text-center">Cancel</div>
          </button>
          <button :disabled="isEditJobScheduleConfig === true ||
            isUpdating === true ||
            editAcc.JobName === null ||
            editAcc.JobName == '' ||
            editAcc.ScheduleDate === null ||
            noCheckedHours === true ||
            duplicateMessage !== ''
            " type="button" class="btn btn-primary h-10 mr-1 text-right" @click="onEditJobScheduleClicked">
            <div class="flex flex-col sm:flex-row justify-end items-center">
              <div class="text-center">
                {{
                  isUpdating
                  ? 'Updating'
                  : 'Update'
                }}
              </div>
              <LoadingIcon v-if="isUpdating" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Super Large Slide Over Content -->
</template>
<style scoped></style>
<script>
// import moment from 'moment'
import { jobScheduleService } from '@/services'
import 'mosha-vue-toastify/dist/style.css'
import { mapGetters } from 'vuex'
import imgEmptyFolder from '@/assets/images/EmptyFolder64.png'
import imgExpired from '@/assets/images/Expired64.png'
import imgDeadlineExpired from '@/assets/images/DeadlineExpired64.png'
import imgSecurityWarning from '@/assets/images/SecurityWarning64.png'

export default {
  data() {
    return {
      listAccounts: [],
      processedItems: {},
      state: { invalidToast: false, notifyMessage: '' },
      imgDeadlineExpired,
      imgExpired,
      imgSecurityWarning,
      imgEmptyFolder,
      isLoading: false,
      isContentLoaded: false,
      isDisableSearch: true,
      isEditJobScheduleConfig: false,
      isUpdating: false,
      isEditing: false,
      currentPage: 1,
      pageSize: 3,
      lastPage: 0,
      currentIndex: 0,
      scheduleDate: null,
      notificationMessage: {
        title: '',
        body: '',
        iserror: false
      },
      searchQuery: null,
      listBankAccountNumbers: [],
      editAcc: {
        JobName: null,
        ScheduleDate: null,
        JobId: null,
        workingDateDay: null,
        GroupBankStatementConfigId: null
      },
      checkedScheduleTime: [],
      checkedHours: {
        times: []
      },
      duplicateMessage: '',
      defaultHourIntraday: ['08:00', '11:00', '13:00', '16:00', '18:00', '20:00'],
      defaultHourBackdate: ['07:30'],
      statusOptions: [
        { label: 'Active', value: 'Active', iconClass: '' },
        { label: 'Inactive', value: 'Inactive', iconClass: '' },
        { label: 'Error', value: 'Error', iconClass: '' }
      ],
      forceUpdate: 0
    }
  },
  computed: {
    ...mapGetters({
      provider: 'main/GET_PROVIDER'
    }),
    isBackdateChecked: function () {
      return (this.editAcc.ScheduleDate === 'Everyday' || this.editAcc.ScheduleDate === 'Weekday') && this.editAcc.workingDateDay === '-1'
    },
    inputType() {
      return this.isUpdating ? 'hidden' : 'text'
    },
    inputPlaceholder() {
      return this.isUpdating ? '' : '###-#-#####-#'
    },
    isInputDisabled() {
      return (bankAccount) => {
        return this.isUpdating || !bankAccount.isUserEnable
      }
    },
    noCheckedHours() {
      if (this.editAcc.workingDateDay === '-1') {
        return false
      }
      return (this.checkedHours?.times ?? []).length === 0
    },
    filteredAccounts() {
      if (!this.searchQuery) {
        return this.listAccounts
      }
      const searchWords = this.searchQuery.toLowerCase().split(' ')
      return this.listAccounts.filter((item) => {
        return searchWords.every(word =>
          item.JobName.toLowerCase().includes(word) ||
          // item.CredentialName.toString().includes(word) ||
          item.CredentialName.toLowerCase().includes(word) ||
          (item.scretVault ? item.scretVault.name.toLowerCase().includes(word) : false)
        )
      })
    }
  },
  watch: {
    listAccounts: {
      handler(newVal) {
        newVal.forEach((newItem, i) => {
          if (newItem.BankAccountActive == 0 && !this.processedItems[newItem.GroupBankStatementConfigId]) {
            this.processedItems[newItem.GroupBankStatementConfigId] = true
            this.onRowBankAccountFalse(newItem)
            console.log('WATCH', newItem)
          }
        })
      },
      deep: true
    },
    isBackdateChecked(newVal) {
      if (newVal) {
        if (!this.checkedHours.times.includes('07:30')) {
          this.checkedHours.times.push('07:30')
        }
      } else {
        const index = this.checkedHours.times.indexOf('07:30')
        if (index !== -1) {
          this.checkedHours.times.splice(index, 1)
        }
      }
    },
    'editAcc.JobName': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          const editAccParsed = JSON.parse(JSON.stringify(this.editAcc))
          this.checkJobNameDuplicate(editAccParsed)
        }
      }
    }
  },
  methods: {
    async getJobScheduleConfig() {
      this.isLoading = true
      try {
        const response = await jobScheduleService.GetJobScheduleConfig({
          page: 1,
          limit: 100000
        })
        this.listAccounts = response.map(item => ({
          ...item,
          checkedScheduleTime: {
            ...item.checkedScheduleTime,
            times: this.updateAndSortTimes(item.checkedScheduleTime.times)
          }
        }))
        console.log('LIST', this.listAccounts)
      } catch (error) {
        console.error('API request failed:', error)
      } finally {
        this.isLoading = false
      }
    },
    updateAndSortTimes(times) {
      return times.map(time => time === '07:30' ? '08:00' : time).sort((a, b) => a.localeCompare(b))
    },
    async getBankAccountNumber(groupBankStatementConfigId) {
      const res = await jobScheduleService.GetBankAccountNumber(groupBankStatementConfigId)
      this.listBankAccountNumbers = res
    },
    async getCheckedScheduleTime(GroupBankStatementConfigId) {
      const res = await jobScheduleService.GetCheckedScheduleTime(GroupBankStatementConfigId)
      this.checkedHours = res
      console.log(`Checked Hours ${GroupBankStatementConfigId}:`, this.checkedHours)
    },
    async onOpenEditAccountClicked(item) {
      this.isLoading = false
      this.isEditJobScheduleConfig = false
      this.isEditing = true
      this.editAcc = {
        ...item,
        JobName: item.JobName,
        bankNo: item.bankAccountNo,
        isUserEnable: item.isUserEnable,
        workingDateDay: item.workingDateDay,
        ScheduleDate: item.ScheduleDate,
        GroupBankStatementConfigId: item.GroupBankStatementConfigId
      }
      cash('#edit-modal').modal('show')
      item?.GroupBankStatementConfigId ? this.getBankAccountNumber(item.GroupBankStatementConfigId) : ''
      item?.GroupBankStatementConfigId ? this.getCheckedScheduleTime(item.GroupBankStatementConfigId) : ''
    },
    async onEditJobScheduleClicked() {
      this.isUpdating = true
      try {
        const payload = {
          id: this.editAcc.JobId,
          JobName: this.editAcc.JobName,
          ScheduleDate: this.editAcc.ScheduleDate,
          isUserEnable: this.editAcc.isUserEnable,
          workingDateDay: this.editAcc.workingDateDay,
          GroupBankStatementConfigId: this.editAcc.GroupBankStatementConfigId,
          checkedHours: [...this.checkedHours.times]
        }
        // console.log('PAYLOAD', payload)
        await jobScheduleService.UpdateJobScheduleConfig(payload)
        cash('#edit-modal').modal('hide')
        this.$helpers.showToastify(
          'Successfully',
          'updated job schedule',
          false
        )
        await this.getJobScheduleConfig()
        this.onEditAccountClosed()
      } catch (e) {
        this.isEditing = false
        this.isUpdating = false
        this.$helpers.showToastify('Failed to update job name!', e.message, true)
      }
      this.isUpdating = false
    },
    async onEditAccountClosed() {
      this.editAcc = {
        JobId: null,
        ScheduleDate: null,
        JobName: null,
        workingDateDay: null,
        isUserEnable: null,
        GroupBankStatementConfigId: null,
        checkedScheduleTime: {
          GroupBankStatementConfigId: null,
          times: []
        }
      }
      this.checkedHours = {
        times: []
      }
      this.isEditJobScheduleConfig = false
      this.isUpdating = false
      this.isEditing = false
    },
    async onRowBankAccountFalse(item) {
      console.log('watchItem', item)
      try {
        const res = await jobScheduleService.InActiveJobScheduleConfig(item?.GroupBankStatementConfigId, { active: false })
        if (res?.statusCode === null || res?.statusCode === undefined) {
          console.log('onRowBankAccountFalse', res.message)
        }
      } catch (e) {
        console.log('onRowBankAccountFalse', e.message)
      }
    },
    async onRowActiveBankClicked(item) {
      console.log('item', item)
      const temp = Number(item?.IsActive) <= 0
      console.log('temp', temp)
      try {
        const res = await jobScheduleService.ActiveJobScheduleConfig(item?.GroupBankStatementConfigId, { active: temp })
        if (res.message === 'Bank account must active less than one and ScheduleDate and Time is not setup.') {
          this.$helpers.showToastify(`${item.JobName}!`, res.message, true)
          return
        }
        if (res.message === 'Bank account is active less than one.') {
          this.$helpers.showToastify(`${item.JobName}!`, res.message, true)
          return
        }
        if (res.message === 'Please setup ScheduleDate and ScheduleTime') {
          this.$helpers.showToastify(`${item.JobName}!`, res.message, true)
          return
        }
        if (res?.statusCode == null) {
          await this.getJobScheduleConfig()
          this.$helpers.showToastify(`${item.JobName} ${temp ? 'active' : 'inactive'} successfully!`)
          return
        }
        this.$helpers.showToastify(
          `${item.JobName} ${temp ? 'active' : 'inactive'} failed!`, res.message, true
        )
      } catch (e) {
        this.$helpers.showToastify('Failed to active or inactive job schedule config!', e.message, true)
      }
    },
    // async onRowActiveBankClicked(item) {
    //   console.log('item', item)
    //   let temp = Number(item?.IsActive) > 0
    //   temp = !temp
    //   try {
    //     const res = await jobScheduleService.ActiveJobScheduleConfig(item?.GroupBankStatementConfigId, { active: temp })
    //     if (res.message === 'ScheduleDate is null for one or more items.') {
    //       this.$helpers.showToastify(`${item.JobName}!`, res.message, true)
    //     }
    //     if (res?.statusCode === null || res?.statusCode === undefined) {
    //       await this.getJobScheduleConfig()
    //       this.$helpers.showToastify(
    //         temp === true
    //           ? `${item.JobName} active successfully!`
    //           : `${item.JobName} inactive successfully!`
    //       )
    //     } else {
    //       this.$helpers.showToastify(
    //         temp === true
    //           ? `${item.JobName} active failed!`
    //           : `${item.JobName} inactive failed!`,
    //         res.message,
    //         true
    //       )
    //     }
    //   } catch (e) {
    //     this.$helpers.showToastify(
    //       'Failed to active or inactive job schedule config!', e.message, true)
    //   }
    // },
    shortJobName(input, length) {
      return input.length > length ? input.substring(0, length) + '...' : input
    },
    async checkJobNameDuplicate(data) {
      try {
        const payloads = {
          jobName: data.JobName,
          groupBankStatementConfigId: data.GroupBankStatementConfigId
        }
        const response = await jobScheduleService.CheckJobNameDuplicate(payloads)
        if (response === true) {
          this.duplicateMessage = 'This job name is a duplicate.'
        } else {
          this.duplicateMessage = ''
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getLoadJobScheduleConfig() {
      const res = await jobScheduleService.GetLoadJobScheduleConfig()
      console.log('getLoadJobScheduleConfig', res)
    }
  },
  async created() {
    console.log('provider', this.provider?.isConfig)
  },
  async mounted() {
    await this.getLoadJobScheduleConfig()
    await this.getJobScheduleConfig()
    this.isContentLoaded = true
  }
}
</script>
